<template>
  <div class="main-menu">
    <h1>Hrenry Game</h1>
    <div class="menu-buttons">
      <button @click="goToTasks">Задания</button>
      <button @click="goToBattle">Сражения</button>
      <button @click="goToCharacter">Персонаж</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToTasks() {
      this.$router.push('/tasks');
    },
    goToBattle() {
      this.$router.push('/battle');
    },
    goToCharacter() {
      this.$router.push('/character');
    }
  }
}
</script>

<style scoped>
.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.menu-buttons {
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  position: fixed;
  bottom: 0;
  background-color: #3f51b5; /* Цвет для меню */
}

button {
  flex: 1;
  margin: 0 10px;
  padding: 15px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e0e0e0;
}
</style>
