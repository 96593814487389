<template>
  <div class="tasks">
    <h2>Задания</h2>
    <ul>
      <li v-for="(task, index) in tasks" :key="index">
        {{ task.description }}
        <button @click="completeTask(index)">Выполнить</button>
      </li>
    </ul>
    <p v-if="feedbackMessage" class="feedback">{{ feedbackMessage }}</p>
    <button @click="goBack">Назад</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tasks: [
        { description: 'Собрать 10 бананов' },
        { description: 'Победить врага в сражении' },
        { description: 'Исследовать джунгли' },
      ],
      feedbackMessage: '',
    };
  },
  methods: {
    completeTask(index) {
      this.feedbackMessage = `Задание выполнено: ${this.tasks[index].description}`;
      this.tasks.splice(index, 1); // Удаляем задание из списка
      setTimeout(() => {
        this.feedbackMessage = ''; // Скрываем сообщение через 3 секунды
      }, 3000);
    },
    goBack() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.tasks {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

button {
  padding: 5px 10px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #5c6bc0;
}

.feedback {
  margin-top: 20px;
  color: green;
  font-weight: bold;
}
</style>
