import { createRouter, createWebHistory } from 'vue-router'
import MainMenuComponent from '../components/MainMenuComponent.vue'
import BattleComponent from '../components/BattleComponent.vue'
import TasksComponent from '../components/TasksComponent.vue'
import CharacterComponent from '../components/CharacterComponent.vue'

const routes = [
  { path: '/', component: MainMenuComponent },
  { path: '/battle', component: BattleComponent },
  { path: '/tasks', component: TasksComponent },
  { path: '/character', component: CharacterComponent }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
