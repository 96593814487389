<template>
  <div class="battle">
    <h2>Сражения</h2>
    <p>Здесь будет логика сражений.</p>
    <button @click="goBack">Назад</button>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.battle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #5c6bc0;
}
</style>
