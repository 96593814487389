<template>
  <router-view/>
</template>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #e0e0e0;
}

h1, h2 {
  color: #3f51b5;
}

button {
  font-family: inherit;
}
</style>

